<template>
  <div class="box">
    <div class="box-body">
      <el-row class="box-body-row" style="margin-top: 5px">
        <el-col :span="12">
          <div class="box-body-col-text">
            <span class="box-body-col-text">基础服务</span>
            <span class="box-body-col-text2">Basic Services</span>
          </div>
          <div class="box-body-col">
            <el-button
              v-for="(item, index) in consoleArray.slice(0, 4)"
              :key="index"
              @click="buy(item)"
              type="text"
            >
              {{ item }}
            </el-button>
          </div>
        </el-col>

        <el-col :span="12" :offset="0">
          <div class="box-body-col-text">
            <span class="box-body-col-text">BaaS服务</span>
            <span class="box-body-col-text2">BaaS Services</span>
          </div>
          <div class="box-body-col">
            <el-button
              v-for="(item, index) in consoleArray.slice(4, 8)"
              :key="index"
              @click="buy(item)"
              type="text"
            >
              {{ item }}
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row class="box-body-row">
        <el-col :span="24">
          <div class="box-body-col-text">
            <span class="box-body-col-text">增值服务</span>
            <span class="box-body-col-text2">Value-Add Services</span>
          </div>
          <div class="box-body-col">
            <el-button
              v-for="(item, index) in consoleArray.slice(8, 15)"
              :key="index"
              @click="buy(item)"
              type="text"
            >
              {{ item }}
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row class="box-body-row">
        <el-col :span="24">
          <div class="box-body-col-text">
            <span class="box-body-col-text">行业服务</span>
            <span class="box-body-col-text2">Industry Services</span>
          </div>
          <div class="box-body-col">
            <el-button
              v-for="(item, index) in consoleArray.slice(15, 21)"
              :key="index"
              @click="buy(item)"
              type="text"
            >
              {{ item }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      consoleArray: [
        '浏览器服务',
        '跨链服务',
        '智能合约和审计',
        '一体机',
        '蚂蚁BaaS服务',
        '腾讯TBaaS服务',
        '华为BCS服务',
        '易居E·BaaS',
        '存证服务',
        '隐私计算',
        '预言机服务',
        '数字身份',
        '数字资产',
        '数字签章',
        '私钥托管',
        '司法链服',
        '上证链服',
        '保交链服',
        '梧桐链服',
        '蚂蚁溯源链服务',
      ],
    };
  },
  methods: {
    buy(name) {
      console.log(this.$route, this.$router.options.routes, name);
      this.$router.options.routes.forEach((item) => {
        if (item.props.label === name) {
          this.$router
            .push({
              name: 'Form',
              query: {
                id: item.props.formId,
                groupName: name,
              },
            })
            .catch((e) => e);
          // return item.props.formId;
        }
        // return 1;
      });
      // this.$router
      //   .push({
      //     name: 'Form',
      //     query: {
      //       id, // this.$route.matched.slice(-1)[0].props.default.formId,
      //       groupName: name, // this.$route.matched.slice(-1)[0].props.default.title,
      //     },
      //   })
      //   .catch((e) => e);
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box-body {
  width: 70%;
}

.box-body-row {
  display: flex;
  height: 220px;
  align-items: center;
}

.box-body-col {
  display: flex;
  justify-content: flex-start;
}

.box-body-col-text {
  text-align: left;
  color: #2285ff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: PingFang SC Bold;
}

.box-body-col-text2 {
  text-align: left;
  color: #e2e2e2;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-left: 10px;
  font-family: Source Han Sans CN Bold;
}

.el-button--text {
  color: #888888;
  font-family: PingFang SC Medium;
}

.el-button--text:hover {
  color: #409eff;
}

.el-button + .el-button {
  margin-left: 40px;
}

.el-divider--horizontal {
  margin: 5px 0;
}
</style>
